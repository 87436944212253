import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DragType } from '@app/enums';
import { EventModel } from '@reducers/orm/event/event.model';
import { OpenShiftModel } from '@reducers/orm/open-shift/open-shift.model';
import { RequiredShiftModel } from '@reducers/orm/required-shift/required-shift.model';
import { ScheduleModel } from '@reducers/orm/schedule/schedule.model';
import { ShiftModel } from '@reducers/orm/shift/shift.model';
import { TeamModel } from '@reducers/orm/team/team.model';
import { ScheduleItemModel } from '@reducers/store/schedule/schedule.n.model';
import { TooltipDirective, TooltipModule } from '@sb/tooltip';
import { isColorDark } from '@shared/contrast.helper';
import { FreeDraggableDirective } from '@shared/drag-drop/directives';
import { SFDragDropModule } from '@shared/drag-drop/sf-drag-drop.module';
import { LightenHexPipe } from '@shared/helpers/lighten-hex.pipe';
import { ColorType, lightenHex } from '@shiftbase-com/utilities';
import { Subscription } from 'rxjs';

import { IconComponent } from '../../../../../shared/icon.component';
import { ScheduleNEmployeeActionService } from '../../../../shared/schedule-actions/action-services/schedule-n-employee-action.service';
import { ScheduleNEventActionService } from '../../../../shared/schedule-actions/action-services/schedule-n-event-action.service';
import { ScheduleNOpenShiftActionService } from '../../../../shared/schedule-actions/action-services/schedule-n-open-shift-action.service';
import { ScheduleNRequiredShiftActionService } from '../../../../shared/schedule-actions/action-services/schedule-n-required-shift-action.service';
import { NDraggable } from '../../../../shared/schedule-actions/interfaces';
import { ScheduleNActionService } from '../../../../shared/schedule-actions/schedule-n-action.service';

@Component({
  selector: 'schedule-base-item',
  templateUrl: './schedule-base-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgClass, NgIf, IconComponent, TooltipModule, SFDragDropModule, LightenHexPipe],
})
export class ScheduleBaseItemComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  public scheduleItem: ScheduleItemModel;

  @Input()
  public shift: ShiftModel;

  @Input()
  public team: TeamModel;

  @Input()
  public isConflicted: boolean;

  @Input()
  public color: string;

  @Input()
  public draggable?: NDraggable;

  @Input()
  public shouldDisplayGhostWhenDraggingButNotCopying: boolean;

  @ViewChild(FreeDraggableDirective, { static: true })
  public draggableComponent: FreeDraggableDirective;

  @ViewChild(TooltipDirective, { static: true })
  public tooltip: TooltipDirective;

  @Input()
  public isDisabled: boolean;

  @Output()
  public dragStart = new EventEmitter();
  @Output()
  public dragEnd = new EventEmitter();

  @Output()
  public onDelete = new EventEmitter();

  @Input()
  public disableHoverButtons: boolean;

  @Input()
  public mode: 'employee' | 'team';

  public isDragging: boolean;
  public isCopying: boolean;
  public isDraggingGracePeriod = false;

  public lightenHex = lightenHex;
  public isColorDark = isColorDark;
  public colorType = ColorType;

  public textColorLight: boolean;

  private dataSubs = new Subscription();

  public constructor(
    private scheduleActionService: ScheduleNActionService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private router: Router,
    private employeeActionService: ScheduleNEmployeeActionService,
    private requiredshiftActionService: ScheduleNRequiredShiftActionService,
    private openShiftActionService: ScheduleNOpenShiftActionService,
    private eventActionService: ScheduleNEventActionService,
    private readonly route: ActivatedRoute,
  ) {}

  public ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.dataSubs.add(
        this.draggableComponent.onDragStart.subscribe(() => {
          this.onDragStart();
          this.updateView();
        }),
      );

      this.dataSubs.add(
        this.draggableComponent.onDragEnd.subscribe(() => {
          this.onDragEnd();
          this.updateView();
        }),
      );
    });
  }

  public ngOnChanges() {
    this.textColorLight = isColorDark(lightenHex(this.color ?? '#2399e4', this.colorType.HEX));
  }

  public updateView() {
    this.cd.detectChanges();
  }

  private onDragStart() {
    this.isDragging = true;
    this.tooltip.hide();
    this.scheduleActionService.isDragging.next(this.draggable);
    this.dragStart.emit();

    // This should be done on the next stack, else you won't have a drag preview (dragging nothing)
    setTimeout(() => {
      if (this.isDragging) {
        this.isDraggingGracePeriod = true;
        this.updateView();
      }
    }, 0);
  }

  private onDragEnd() {
    this.isDragging = false;
    this.isDraggingGracePeriod = false;
    this.isCopying = false;
    this.scheduleActionService.isDragging.next(null);
    this.dragEnd.emit();
  }

  public ngOnDestroy(): void {
    if (this.tooltip) {
      this.tooltip.hide();
    }

    this.dataSubs.unsubscribe();
  }

  public edit(event: MouseEvent) {
    event.stopPropagation();
    this.zone.run(() => {
      switch (this.draggable.type) {
        case DragType.SCHEDULE: {
          const modalRouteSegments: (
            | string
            | {
                originator: string;
              }
          )[] = ['schedule-shift', this.scheduleItem.occurrence_id, 'edit'];

          // TODO because the way modal routes are setup, we don't have access to the original route params in a clean way
          // so we have to pass along the originator in the modal route
          if (this.route.snapshot.params?.['originator']) {
            modalRouteSegments.push({ originator: this.route.snapshot.params['originator'] });
          }

          this.router.navigate([
            '',
            {
              outlets: {
                modal: modalRouteSegments,
              },
            },
          ]);
          break;
        }
        case DragType.OPEN_SHIFT: {
          this.router.navigate([
            '',
            { outlets: { modal: ['schedule-open-shift', this.scheduleItem.occurrence_id, 'edit'] } },
          ]);
          break;
        }
        case DragType.REQUIRED_SHIFT: {
          this.router.navigate([
            '',
            {
              outlets: {
                modal: ['schedule-required-shift', this.scheduleItem.occurrence_id, 'edit'],
              },
            },
          ]);
          break;
        }
        case DragType.EVENT: {
          this.router.navigate(['', { outlets: { modal: ['event', this.scheduleItem.id, 'edit'] } }]);
          break;
        }
      }
    });
  }

  public view(event: MouseEvent) {
    event.stopPropagation();
    switch (this.draggable.type) {
      case DragType.SCHEDULE: {
        this.router.navigate(['', { outlets: { modal: ['schedule-shift', this.scheduleItem.occurrence_id] } }]);
        break;
      }
      case DragType.OPEN_SHIFT: {
        this.router.navigate(['', { outlets: { modal: ['schedule-open-shift', this.scheduleItem.occurrence_id] } }]);
        break;
      }
      case DragType.REQUIRED_SHIFT: {
        this.router.navigate([
          '',
          { outlets: { modal: ['schedule-required-shift', this.scheduleItem.occurrence_id] } },
        ]);
        break;
      }
      case DragType.EVENT: {
        this.router.navigate(['', { outlets: { modal: ['event', this.scheduleItem.id] } }]);
        break;
      }
    }
  }

  public create(event: MouseEvent) {
    event.stopPropagation();
    switch (this.draggable.type) {
      case DragType.SCHEDULE: {
        const shift: ScheduleModel = <ScheduleModel>this.draggable.shift;
        this.employeeActionService.handleCreate(
          this.team.department_id,
          this.team.id,
          this.mode === 'employee' ? shift.user_id : undefined,
          shift.date,
        );
        break;
      }
      case DragType.OPEN_SHIFT: {
        const shift: OpenShiftModel = <OpenShiftModel>this.draggable.shift;
        this.openShiftActionService.handleCreate(shift.department_id, shift.team_id, shift.date);
        break;
      }
      case DragType.REQUIRED_SHIFT: {
        const shift: RequiredShiftModel = <RequiredShiftModel>this.draggable.shift;
        this.requiredshiftActionService.handleCreate(shift.date, shift.department_id, shift.team_id);
        break;
      }
      case DragType.EVENT: {
        const eventItem: EventModel = <EventModel>this.draggable.shift;
        this.eventActionService.handleCreate(eventItem.date, eventItem.department_id);
        break;
      }
    }
  }

  public delete(event: MouseEvent) {
    event.stopPropagation();
    this.onDelete.emit();
  }
}
